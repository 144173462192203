<template>
    <modal ref="modalIntegrantesEquipo" titulo="Editar integrantes del equipo" tamano="modal-lg">
        <div class="row mx-0 justify-center">
            <div class="col-auto px-3" />
            <div class="col-10">
                <p class="text-general pl-3 f-14">Añadir integrantes</p>
                <el-select v-model="value" placeholder="Select" class="w-100">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div v-for="(data, d) in integrantes" :key="d" class="row mx-0 justify-center align-items-center mb-2 ">
            <div class="col-auto px-0">
                <i class="icon-star text-orange f-20" />
            </div>
            <div class="col-10">
                <div class="row align-items-center mx-0 border bg-light-f5 py-2 px-3 br-6">
                    <img :src="url_image" width="28" height="28" class="obj-cover rounded-circle" />
                    <div class="col tres-puntos text-center text-general f-15">
                        {{ data.nombre }} Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat similique, hic dolores reiciendis incidunt, maxime autem quod, suscipit explicabo nisi minima mollitia! Maiores tenetur quos ducimus autem. Veniam, dolorum quisquam!
                    </div>
                    <div class="col-4 text-center text-general f-15">
                        Horeca
                    </div>
                    <div class="col-auto px-2">
                        <i class="icon-cancel text-general f-20 cr-pointer" />
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            integrantes: [
                {
                    favorito: false,
                    nombre: 'Marta Ardila'
                },
                {
                    favorito: false,
                    nombre: 'Jose Robledo'
                },
                {
                    favorito: false,
                    nombre: 'Diana Cardona'
                },
                {
                    favorito: false,
                    nombre: 'Camilo Guerrero'
                }
            ],
            value: '',
            url_image: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg'
        }
    },
    methods: {
        toggle(){
            this.$refs.modalIntegrantesEquipo.toggle();
        }
    }
}
</script>